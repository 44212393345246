import React from 'react';

/**
 * Render AboutUs
 * @returns {React.FC} - return
 */
const AboutUs = () => {
  return (
    <div className="section about-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-1">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    Future-Proof Your Career
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Technology is transforming every industry. Whether you dream of becoming a
                    software developer, a digital marketer, or an entrepreneur, the ability to adapt
                    and innovate with tech-based skills will set you apart in the job market.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingtwo">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo">
                    Earn While You Learn
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    At Buland Academy, we don’t just teach you the skills—you’ll learn how to
                    monetize them. Our courses are designed to help you start earning even before
                    you graduate. Whether it’s freelancing, launching your own business, or finding
                    part-time work, our training prepares you for real-world success.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapse"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Entrepreneurship at Its Core
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Do you have a business idea but don’t know how to bring it to life? We’ll teach
                    you how to turn your ideas into reality with entrepreneurial skills that include
                    everything from building a business plan to marketing your services. You’ll gain
                    the confidence to launch your own ventures and be your own boss.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    Learn from Industry Experts
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Our team of experienced instructors and mentors will guide you through every
                    step of your journey. With hands-on projects, real-world case studies, and
                    up-to-date industry insights, you’ll be learning from those who have already
                    walked the path to success.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive">
                    Start Small, Dream Big
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Whether you want to pursue a career in technology, or business, or create
                    something entirely your own, Buland Academy is here to help you get started. The
                    skills you gain today will lay the foundation for a successful, sustainable
                    future, no matter where your passions take you.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 align-self-center">
            <div className="section-heading">
              <h6>About Us</h6>
              <h2>Transform Your Future Today</h2>
              <p className="mb-4">
                <strong className="text-primary">Join Buland Academy </strong> and take the first
                step towards a future filled with opportunities. Whether you aspire to be a tech
                innovator, a savvy entrepreneur, or a skilled professional in a thriving industry,
                our programs are designed to help you unlock your true potential and build a
                foundation for long-term success
              </p>
              <div>
                <a
                  target="blink"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSctQgZqNpCKg-vSPtkaKp88F91jtKX-HGufhpXGrIDNXUbK7A/viewform?usp=sf_link"
                  className="btn btn-md fw-bold btn-outline-success rounded-pill">
                  Apply <i className="fa fa-link"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
