import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PreLoader } from 'components';
import { Home, NotFound } from 'views';

/**
 * Render Landing page
 * @returns {React.FC}- return
 */
function App() {
  const MILISECOND = 2000;
  const [loader, setLoader] = useState<boolean>(true);

  // Hide loader after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, MILISECOND);

    // Cleanup timer
    return () => {
      return clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {loader ? (
        <PreLoader />
      ) : (
        <Router>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
