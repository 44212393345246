/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';

/**
 * Render Facts students and visitors
 * @returns {React.FC} - return
 */
const Facts: React.FC = () => {
  const week = 7;
  const seconds = 60;
  const dayHours = 24;
  const miliseconds = 1000;

  // Start date: October 5, 2024
  const startDate: Date = new Date('2024-10-05');

  // Happy Students counter settings
  const studentsInitialValue: number = 0;
  const studentsIncrementValue: number = 0; // Roughly increment by 5 per week

  // Visitors counter settings
  const visitorsInitialValue: number = 118; // Starting at 118
  // const visitorsBaseIncrement: number = 33; // Increment by odd numbers starting at 33

  // State variables for counters
  const [studentsCount, setStudentsCount] = useState<number>(studentsInitialValue);
  const [visitorsCount, setVisitorsCount] = useState<number>(visitorsInitialValue);

  /***/
  const calculateWeeksPassed = (startDate: Date): number => {
    const currentDate: Date = new Date();
    const timeDiff: number = currentDate.getTime() - startDate.getTime();
    return Math.floor(timeDiff / (miliseconds * seconds * seconds * dayHours * week)); // Convert time difference to weeks
  };

  /***/
  const calculateVisitorsIncrement = (weeksPassed: number): number => {
    let increment = 0;
    for (let i = 0; i < weeksPassed; i++) {
      increment += 33 + i * 2; // Increment by odd numbers: 33, 35, 37, etc.
    }
    return increment;
  };

  // Update counters on component mount
  useEffect(() => {
    const weeksPassed = calculateWeeksPassed(startDate);

    // Update Happy Students counter
    const updatedStudentsCount = studentsInitialValue + weeksPassed * studentsIncrementValue;
    setStudentsCount(updatedStudentsCount);

    // Update Visitors counter with odd number increment pattern
    const updatedVisitorsCount = visitorsInitialValue + calculateVisitorsIncrement(weeksPassed);
    setVisitorsCount(updatedVisitorsCount);
  }, []);

  return (
    <div className="section fun-facts">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="wrapper">
              <div className="row">
                {/* Happy Students Counter */}
                <div className="col-lg-3 col-md-6">
                  <div className="counter">
                    <h2 className="timer count-title count-number" data-speed="1000">
                      {studentsCount}
                    </h2>
                    <p className="count-text">Happy Students</p>
                  </div>
                </div>

                {/* Course Hours (Static) */}
                <div className="col-lg-3 col-md-6">
                  <div className="counter">
                    <h2 className="timer count-title count-number" data-speed="1000">
                      123
                    </h2>
                    <p className="count-text">Course Hours</p>
                  </div>
                </div>

                {/* Visitors Counter */}
                <div className="col-lg-3 col-md-6">
                  <div className="counter">
                    <h2 className="timer count-title count-number" data-speed="1000">
                      {visitorsCount}
                    </h2>
                    <p className="count-text">Visitors</p>
                  </div>
                </div>

                {/* Years Experience (Static) */}
                <div className="col-lg-3 col-md-6">
                  <div className="counter end">
                    <h2 className="timer count-title count-number" data-speed="1000">
                      10
                    </h2>
                    <p className="count-text">Years Experience</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facts;
