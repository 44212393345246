import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * Render Testimonials Slider
 * @returns {React.FC} - Testimonials component
 */
const Testimonials = () => {
  // Slider settings
  const settings = {
    className: 'center',
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable auto-play
    autoplaySpeed: 2000
  };

  return (
    <div className="section testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <Slider {...settings}>
              {/* Slide 1 */}
              <div className="item">
                <p>
                  “Incredible experience with a team that truly cares about skill-building!
                  Buland&apos;s innovative teaching approach helped me gain the practical skills I
                  needed for my career.”
                </p>
                <div className="author">
                  <img
                    className="w-75 h-75 rounded-circle"
                    src="assets/images/jaffar.png"
                    alt="Testimonial Author"
                  />
                  <span className="category">Digital Marketing</span>
                  <h4>Freelancing</h4>
                </div>
              </div>

              {/* Slide 2 */}
              <div className="item">
                <p>
                  “I joined the pilot phase at Buland.pk, and it was a great learning experience!
                  Their focus on new technologies like AI and their practical teaching gave my
                  skills a strong boost.”
                </p>
                <div className="author">
                  <img
                    className="w-75 h-75 rounded-circle"
                    src="assets/images/ZAFAR.png"
                    alt="Testimonial Author"
                  />
                  <span className="category">Founder</span>
                  <h4>Sharp Calls</h4>
                </div>
              </div>

              {/* Slide 3 */}
              <div className="item">
                <p>
                  “Buland really knows how to equip students with practical skills that are directly
                  applicable in the tech industry. I highly recommend this course to anyone looking
                  to break into web development!.”
                </p>
                <div className="author">
                  <img
                    className="w-75 h-75 rounded-circle"
                    src="https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2264922221.jpg"
                    alt="Testimonial Author"
                  />
                  <span className="category">Web Developer</span>
                  <h4>Anum Ameer</h4>
                </div>
              </div>
            </Slider>
          </div>
          <div className="col-lg-5 align-self-center">
            <div className="section-heading">
              <h6>TESTIMONIALS</h6>
              <h2>What they say about us?</h2>
              <p>
                You can search freely on Google using different keywords such as teaching skills,
                buland gallery, buland experts, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
