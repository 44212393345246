import React from 'react';

/***/
const Team = () => {
  // Mock team data
  const executiveTeam = [
    {
      name: 'Ehtisham Ali',
      title: 'COO & Co-Founder',
      image: 'assets/images/ehtisham.png',
      social: {
        facebook: 'https://www.facebook.com/ehtisham.ali.129?mibextid=LQQJ4d',
        linkedin:
          'https://www.linkedin.com/in/ehtisham-ali-36028472?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    },
    {
      name: 'Suleman Khan',
      title: 'CEO & CO-Founder',
      image: 'assets/images/suleman.png',
      social: {
        facebook: 'https://www.facebook.com/suleman.khan26?mibextid=LQQJ4d',
        linkedin:
          'https://www.linkedin.com/in/suleman-khan-75902526?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    },
    {
      name: 'Rameez Ali',
      title: 'CTO & CO-Founder',
      image: 'assets/images/rameez.png',
      social: {
        facebook: 'https://www.facebook.com/MazdoorPanda?mibextid=LQQJ4d',
        linkedin:
          'https://www.linkedin.com/in/rameezaliramxo?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    },
    {
      name: 'Farrukh Nawaz',
      title: `CO-Founder`,
      image: 'assets/images/farrukh.png',
      social: {
        facebook: 'https://www.facebook.com/profile.php?id=61560172887692&mibextid=LQQJ4d',
        linkedin:
          'https://www.linkedin.com/in/farrukh-nawaz-40374441?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    }
  ];

  const techTeam = [
    {
      name: 'Tariq Ali',
      title: 'Software Engineer',
      image: 'assets/images/tariq.png',
      social: {
        facebook: 'https://www.facebook.com/tariqicp322?mibextid=ZbWKwL',
        linkedin:
          'https://www.linkedin.com/in/tariqicp?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    },
    {
      name: 'Sahibzada Abbas',
      title: 'Software Engineer',
      image: 'assets/images/abbas.png',
      social: {
        facebook: 'https://www.facebook.com/sahibzadaabbas.khan?mibextid=LQQJ4d',
        linkedin:
          'https://www.linkedin.com/in/sahibzada-abbas-aab8a3117?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    },
    {
      name: 'Waseem javed',
      title: 'Software Engineer',
      image: 'assets/images/waseem.png',
      social: {
        facebook: 'https://www.facebook.com/Waseemjaved1919',
        linkedin: 'https://www.linkedin.com/in/waseem-javed/'
      }
    },
    {
      name: 'Muhammad Wisal',
      title: 'Software Engineer',
      image: 'assets/images/wisal.png',
      social: {
        facebook: 'https://www.facebook.com/Wisal.Muhammad007?mibextid=LQQJ4d',
        linkedin:
          'https://www.linkedin.com/in/wisal-muhammad-0ba46b139?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
      }
    }
  ];
  const advTeam = [
    {
      name: 'Waqas Ali',
      title: 'Adviser',
      image: 'assets/images/waqas-ali.png',
      social: {
        facebook: 'https://www.facebook.com/MeVickyMayo?mibextid=LQQJ4d',
        linkedin: 'https://www.linkehttps://www.linkedin.com/in/vickymayo/'
      }
    }
  ];
  /***/
  const renderTeamMembers = (team: any) => {
    return team.map((member: any, index: number) => {
      return (
        <div className="col-lg-3 col-md-6" key={index}>
          <div className="team-member">
            <div className="main-content">
              <img src={member.image} alt={member.name} />
              <span className="category">{member.title}</span>
              <h4>{member.name}</h4>
              <ul className="social-icons">
                <li>
                  <a target="blink" href={member.social.facebook}>
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="blink" href={member.social.linkedin}>
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <section className="section courses" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="section-heading m-2">
              <h6 className="p-2">Professional Team</h6>
              <h2 className="m-0">Professional Team</h2>
            </div>
          </div>
        </div>

        <div className="container">
          {/* Tabs Navigation */}
          <center style={{ marginBottom: 100 }}>
            <ul
              className="nav bg-light nav-pills d-inline-flex justify-content-center gap-2 p-2 rounded-pill mb-5"
              id="teamTabs"
              role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill active"
                  id="executive-team-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#executive-team"
                  type="button"
                  role="tab"
                  aria-controls="executive-team"
                  aria-selected="true">
                  Executive Team
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="tech-team-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tech-team"
                  type="button"
                  role="tab"
                  aria-controls="tech-team"
                  aria-selected="false">
                  Tech Team
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="adv-team-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#adv-team"
                  type="button"
                  role="tab"
                  aria-controls="adv-team"
                  aria-selected="false">
                  Adviser Team
                </button>
              </li>
            </ul>
          </center>

          {/* Tab Content */}
          <div className="tab-content" id="teamTabsContent">
            {/* Executive Team Tab */}
            <div
              className="tab-pane fade show active"
              id="executive-team"
              role="tabpanel"
              aria-labelledby="executive-team-tab">
              <div className="row mt-4">{renderTeamMembers(executiveTeam)}</div>
            </div>

            {/* Tech Team Tab */}
            <div
              className="tab-pane fade"
              id="tech-team"
              role="tabpanel"
              aria-labelledby="tech-team-tab">
              <div className="row mt-4 justify-content-center">{renderTeamMembers(techTeam)}</div>
            </div>
            {/* adv Team Tab */}
            <div
              className="tab-pane fade"
              id="adv-team"
              role="tabpanel"
              aria-labelledby="adv-team-tab">
              <div className="row mt-4 justify-content-center">{renderTeamMembers(advTeam)}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
