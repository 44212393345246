import React from 'react';

/**
 * Render Course
 * @returns {React.FC} - return
 */
const Courses = () => {
  return (
    <section className="section courses" id="courses">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="section-heading">
              <h6>Latest Courses</h6>
              <h2>Latest Courses</h2>
            </div>
          </div>
        </div>
        <div className="container">
          {/* <!-- Tabs Navigation --> */}
          <center>
            <ul
              className="nav bg-light nav-pills d-inline-flex justify-content-center gap-2 p-2 rounded-pill mb-5"
              id="courseTabs"
              role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill active"
                  id="web-dev-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#web-dev"
                  type="button"
                  role="tab"
                  aria-controls="web-dev"
                  aria-selected="true">
                  Web Development
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="programming-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#programming"
                  type="button"
                  role="tab"
                  aria-controls="programming"
                  aria-selected="false">
                  Programming
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="marketing-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#marketing"
                  type="button"
                  role="tab"
                  aria-controls="marketing"
                  aria-selected="false">
                  Marketing
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="entrepreneur-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#entrepreneur"
                  type="button"
                  role="tab"
                  aria-controls="entrepreneur"
                  aria-selected="false">
                  Entrepreneurship
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="apps-dev-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#apps-dev"
                  type="button"
                  role="tab"
                  aria-controls="apps-dev"
                  aria-selected="false">
                  Apps Development
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rounded-pill"
                  id="desktop-dev-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#desktop-dev"
                  type="button"
                  role="tab"
                  aria-controls="desktop-dev"
                  aria-selected="false">
                  Desktop Development
                </button>
              </li>
            </ul>
          </center>

          {/* <!-- Tab Content --> */}
          <div className="tab-content" id="courseTabsContent">
            {/* <!-- Web Development Tab --> */}
            <div
              className="tab-pane fade show active"
              id="web-dev"
              role="tabpanel"
              aria-labelledby="web-dev-tab">
              <div className="row event_box">
                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/reactjs.png"
                          height={250}
                          alt="Frontend with React"
                        />
                      </a>
                      <span className="category">Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>ReactJS Development</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/nextjs.jpg"
                          height={250}
                          alt="Next.js Development"
                        />
                      </a>
                      <span className="category">Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>NextJS Development</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img src="assets/images/vuejs.jpg" height={250} alt="Vite & Vue.js" />
                      </a>
                      <span className="category">Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>VueJS Development</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Programming Tab --> */}
            <div
              className="tab-pane fade"
              id="programming"
              role="tabpanel"
              aria-labelledby="programming-tab">
              <div className="row event_box">
                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb border">
                      <a href="#">
                        <img src="assets/images/python.png" height={250} alt="Python Programming" />
                      </a>
                      <span className="category">Programming</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Python Programming</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/c-language.png"
                          height={250}
                          alt="Java Development"
                        />
                      </a>
                      <span className="category">Programming</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>C Programming</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img src="assets/images/cplus.jpg" height={250} alt="C++ Programming" />
                      </a>
                      <span className="category">Programming</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>C++ Programming</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Marketing Tab --> */}
            <div
              className="tab-pane fade"
              id="marketing"
              role="tabpanel"
              aria-labelledby="marketing-tab">
              <div className="row event_box">
                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/digital.jpeg"
                          height={250}
                          alt="Digital Marketing"
                        />
                      </a>
                      <span className="category">Marketing</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Digital Marketing</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img src="assets/images/seo.jpg" height={250} alt="SEO Optimization" />
                      </a>
                      <span className="category">Marketing</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>SEO Optimization</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/social.jpg"
                          height={250}
                          alt="Social Media Marketing"
                        />
                      </a>
                      <span className="category">Marketing</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Social Media Marketing</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- En Tab --> */}
            <div
              className="tab-pane fade"
              id="entrepreneur"
              role="tabpanel"
              aria-labelledby="entrepreneur-tab">
              <div className="row event_box">
                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img src="assets/images/entrepreneur.jpg" height={250} alt="entrepreneur" />
                      </a>
                      <span className="category">Entrepreneurship</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Startup Basics</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/fundraising.jpg"
                          height={250}
                          alt="SEO Optimization"
                        />
                      </a>
                      <span className="category">Entrepreneur</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Fundrasing</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/business.jpg"
                          height={250}
                          alt="Social Media Marketing"
                        />
                      </a>
                      <span className="category">Entrepreneur</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Business Modeling</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Apps Development Tab --> */}
            <div
              className="tab-pane fade"
              id="apps-dev"
              role="tabpanel"
              aria-labelledby="apps-dev-tab">
              <div className="row event_box">
                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img src="assets/images/ios.jpg" height={250} alt="iOS App Development" />
                      </a>
                      <span className="category">Apps Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>iOS App Development</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/android.png"
                          height={250}
                          alt="Android Development"
                        />
                      </a>
                      <span className="category">Apps Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Android Development</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 d-flex mb-30 event_outer">
                  <div className="events_item flex-grow-1 flex-column">
                    <div className="thumb">
                      <a href="#">
                        <img
                          src="assets/images/cross.jpg"
                          height={250}
                          alt="Flutter App Development"
                        />
                      </a>
                      <span className="category">Apps Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Cross App Development</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Desktop Development Tab --> */}
            <div
              className="tab-pane fade"
              id="desktop-dev"
              role="tabpanel"
              aria-labelledby="desktop-dev-tab">
              <div className="row event_box justify-content-center">
                <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer">
                  <div className="events_item">
                    <div className="thumb">
                      <a href="#">
                        <img src="assets/images/electron.jpg" alt="Electron JS Development" />
                      </a>
                      <span className="category">Desktop Development</span>
                      <span className="price">
                        <h6>&#128187;</h6>
                      </span>
                    </div>
                    <div className="down-content">
                      <h4>Electron JS Development</h4>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer">
              <div className="events_item">
                <div className="thumb">
                  <a href="#">
                    <img src="assets/images/course-19.jpg" alt="WPF Development" />
                  </a>
                  <span className="category">Desktop Development</span>
                  <span className="price">
                    <h6>&#128187;</h6>
                  </span>
                </div>
                <div className="down-content">
                  <h4>WPF Development</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer">
              <div className="events_item">
                <div className="thumb">
                  <a href="#">
                    <img src="assets/images/course-20.jpg" alt="WinForms Development" />
                  </a>
                  <span className="category">Desktop Development</span>
                  <span className="price">
                    <h6>&#128187;</h6>
                  </span>
                </div>
                <div className="down-content">
                  <h4>WinForms Development</h4>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
