import React from 'react';
import Banner from './components/banner';
import Services from './components/services';
import AboutUs from './components/aboutUs';
import Courses from './components/courses';
import Facts from './components/facts';
import Team from './components/team';
import Testimonials from './components/testimonials';
import ContactUs from './components/contactUs';
import { Footer, Header } from 'components';
import Partners from './components/partners';
// import Events from './components/events';

/**
 * Home index page
 * @returns {React.FC} - return
 */
const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <Services />
      <AboutUs />
      <Courses />
      <Facts />
      <Team />
      <Testimonials />
      {/* <Events /> */}
      <ContactUs />
      <Partners />
      <Footer />
    </>
  );
};

export default Home;
