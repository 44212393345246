import React, { useState } from 'react';

/**
 * Header index page
 * @returns {React.FC} - return
 */
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  // eslint-disable-next-line require-jsdoc
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <header className="header-area header-sticky">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
              {/*  ***** Logo Start *****  */}
              <a href="index.html" className="logo px-2">
                {/* <h1>Buland</h1> */}
                <div style={{ width: 165 }}>
                  <img src="/brand.png" />
                </div>
              </a>
              {/*  ***** Logo End *****  */}
              {/*  ***** Serach Start *****  */}
              <div className="search-input">
                <form id="search" action="#">
                  <input
                    type="text"
                    placeholder="Type Something"
                    id="searchText"
                    name="searchKeyword"
                    disabled
                  />
                  <i className="fa fa-search"></i>
                </form>
              </div>
              {/* ***** Serach Start ***** */}
              {/* ***** Menu Start ***** */}
              <ul className={`nav ${menuOpen ? 'open' : ''}`}>
                <li className="scroll-to-section">
                  <a href="#top" className="active">
                    Home
                  </a>
                </li>
                <li className="scroll-to-section">
                  <a href="#services">Services</a>
                </li>
                <li className="scroll-to-section">
                  <a href="#courses">Courses</a>
                </li>
                <li className="scroll-to-section">
                  <a href="#team">Team</a>
                </li>
                {/* <li className="scroll-to-section">
                  <a href="#events">Events</a>
                </li> */}
                <li className="scroll-to-section">
                  <a href="#contact">Contact Us!</a>
                </li>
                <li className="scroll-to-section">
                  <a
                    target="blink"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSctQgZqNpCKg-vSPtkaKp88F91jtKX-HGufhpXGrIDNXUbK7A/viewform?usp=sf_link"
                    className="fw-bold border rounded-pill btn btn-light p-0 px-3 text-warning">
                    Apply <i className="fa fa-link"></i>
                  </a>
                </li>
              </ul>
              <a className={`menu-trigger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                <span> Menu </span>
              </a>
              {/* **** Menu End ***** */}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
