import React from 'react';
import Marquee from 'react-fast-marquee';

/**
 * Render Partners
 * @returns {React.FC} - returns
 */
const Partners = () => {
  const partners = [
    {
      id: 1,
      src: 'https://media.licdn.com/dms/image/v2/D4D0BAQH3dj-esMG2gQ/company-logo_200_200/company-logo_200_200/0/1680640516431/smartdealertech_logo?e=2147483647&v=beta&t=lf13n69v86SrTMSnZSaYYh4atfBEc1PJKrEXY14lPvg',
      alt: 'Partner 1'
    },
    {
      id: 2,
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKmRsQG9I7i_LOfp45dSBNaUuFqQ3KVTtpciM3y4GyXQ13yV1TY68zH_H9JR_PPK4h8hc&usqp=CAU',
      alt: 'Partner 2'
    },
    {
      id: 3,
      src: '/assets/images/rasse.png',
      alt: 'Partner 3'
    },
    {
      id: 4,
      src: 'https://cryptokoder.com/wp-content/uploads/2024/02/crypto-koder-white-logo-250x65.png',
      alt: 'Partner 4'
    },
    {
      id: 5,
      src: 'https://innovativegenerations.org/wp-content/uploads/2024/04/Innovative-Generations-Logo.png',
      alt: 'Partner 5'
    }
  ];

  return (
    <section className="container-fluid bg-light">
      <div className="my-5">
        <h2 className="text-center py-2 ">Our Partners</h2>
        <Marquee speed={60} pauseOnHover={true} autoFill>
          {partners.map((logo, idx) => {
            return (
              <a key={idx} href="#">
                <div className="logo-item mx-2 p-5" key={logo.id}>
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="bg-white rounded-pill"
                    style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                  />
                </div>
              </a>
            );
          })}
        </Marquee>
      </div>
    </section>
  );
};

export default Partners;
