import React from 'react';

/**
 * Footer index page
 * @returns {React.FC} - return
 */
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="col-lg-12">
          <p className="align-bottom">
            <strong>BULAND</strong> © {new Date().getFullYear()}. All rights reserved.
            <br />
            <span>Develop a passion for learning. If you do, you will never cease to grow.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
