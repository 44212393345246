import React from 'react';

/**
 * NotFound
 * @returns {React.FC} - return
 */
const NotFound = () => {
  return (
    <section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h2 className="d-flex justify-content-center align-items-center gap-0 mb-2">
                <span className="display-1 fw-bold text-warning">4</span>
                <i className="bi bi-exclamation-circle-fill text-warning display-4">🤔</i>
                <span
                  style={{ transform: 'scaleX(-1)' }}
                  className="display-1 fw-bold text-warning">
                  4
                </span>
              </h2>
              <h3 className="h2 mb-2 fw-bolder text-warning">{`Oops! You're lost.`}</h3>
              <p className="mb-3 text-danger text-bolder">
                The page you are looking was not found!. 😕
              </p>
              <a
                className="btn bsb-btn-xl btn-primary rounded-pill px-3 py-2 fs-6 m-0"
                href="/"
                role="button">
                <i className="fa fa-arrow-alt-circle-left"></i>&nbsp; Back to Home 🤔
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
