import React from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { contactFormValidation } from 'validation';
// const initialValues = {
//   name: '',
//   email: '',
//   message: ''
// };
/**
 * Render ContactUs
 * @returns {React.FC} - return
 */
const ContactUs = () => {
  return (
    <div className="contact-us section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="section-heading">
              <h6>Contact Us</h6>
              <h2>Feel free to contact us anytime</h2>
              <p>
                Thank you for choosing <strong>Buland</strong>. We provide you best services at our
                100% effort. You may support us by sharing our website to your friends.
              </p>
              <div className="special-offer">
                <span className="offer">
                  <i className="fa fa-phone fa-3x mt-1"></i>
                </span>
                <h6>
                  <em>Email : </em>
                  <span className="text-lowercase">contact@buland.pk</span>
                  <b></b>
                </h6>
                <h6>
                  <em>Phone No : </em>+92938300766
                </h6>
                <a href="#">
                  <i className="fa fa-angle-right"></i>
                </a>
              </div>
              <div className="mt-4">
                <h4 className="mb-1 p-0">Socials : </h4>
                <a
                  className="btn btn-link p-0 rounded-pill"
                  href="https://www.linkedin.com/company/buland-academy/">
                  <i className="fab fa-linkedin text-primary p-1 fa-3x"></i>
                </a>
                <a
                  className="btn btn-link p-0 rounded-pill"
                  href="https://www.facebook.com/profile.php?id=61564963253197&mibextid=LQQJ4d">
                  <i className="fab fa-facebook text-primary p-1 fa-3x"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-us-content p-4">
              <iframe
                className="rounded"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.7194579609863!2d72.35566177517582!3d34.02541141914751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dedf2d999dfac9%3A0xa9a679ed1216e409!2sThe%20Orbit%20College%20Lahor%2C%20Swabi!5e0!3m2!1sen!2s!4v1727524281696!5m2!1sen!2s"
                width="100%"
                height="350"
                style={{ border: 'none' }}
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
