import React from 'react';

/**
 * Render Banner
 * @returns {React.FC} returns
 */
const Banner = () => {
  return (
    <div className="main-banner" id="top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="owl-banner">
              <div className="item item-1">
                <div className="header-text">
                  <span className="category">Skillful Buland</span>
                  <h2>Unlock your potential with skills that matter</h2>
                  <p>
                    At Buland Academy, we believe that every student has the potential to achieve
                    greatness. Our mission is to empower school and college students by providing
                    them with cutting-edge technological and entrepreneurship skills that are
                    essential in today’s dynamic world.
                  </p>
                  <div className="buttons">
                    <div className="main-button">
                      <a href="#contact">Contact Us</a>
                    </div>
                    <div className="icon-button">
                      <a href="#">
                        <i className="fa fa-play"></i> {`What's Buland?`}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="item item-2">
                <div className="header-text">
                  <span className="category">Best Result</span>
                  <h2>Get the best result out of your effort</h2>
                  <p>
                    You are allowed to use this company for any educational or commercial purpose.
                    You are allowed to share the website with friends to join and get learn new
                    things.
                  </p>
                  <div className="buttons">
                    <div className="main-button">
                      <a href="#contactUs">Contact Us</a>
                    </div>
                    <div className="icon-button">
                      <a href="#">
                        <i className="fa fa-play"></i> {`What's the best result?`}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item item-3">
                <div className="header-text">
                  <span className="category">Buland will build your skills</span>
                  <h2>Buland Learning helps you save the time</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    temporious incididunt ut labore et dolore magna aliqua suspendisse.
                  </p>
                  <div className="buttons">
                    <div className="main-button">
                      <a href="#contactUs">Contact Us</a>
                    </div>
                    <div className="icon-button">
                      <a href="#">
                        <i className="fa fa-play"></i> {`What's Online Course?`}
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
