import React from 'react';

/**
 * Render Servies
 * @returns {React.FC} - returns
 */
const Services = () => {
  return (
    <div className="services section" id="services">
      <div className="container">
        {/* <h3 className="text-primary lead text-center">Services</h3> */}
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="service-item">
              <div className="icon">
                <img src="assets/images/service-01.png" alt="online degrees" />
              </div>
              <div className="main-content">
                <h4>Hands-On Projects</h4>
                <p className="text-justify">
                  Engage in real-world projects that build practical experience and abilities.
                </p>
                <br />
                <div className="main-button">
                  <a href="#courses">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item">
              <div className="icon">
                <img src="assets/images/service-02.png" alt="short courses" />
              </div>
              <div className="main-content">
                <h4>Expert Mentors</h4>
                <p className="text-justify">
                  Learn from industry professionals and successful entrepreneurs who provide
                  guidance and inspiration.
                </p>
                <div className="main-button">
                  <a href="#courses">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item">
              <div className="icon">
                <img src="assets/images/service-03.png" alt="web experts" />
              </div>
              <div className="main-content">
                <h4>Flexible Learning Paths</h4>
                <p className="text-justify">
                  Choose from online and in-person courses that fit your schedule and learning
                  style.
                </p>
                <div className="main-button">
                  <a href="#courses">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
